import React from 'react';
import {
  FaUsers,
  FaFileContract,
  FaChessQueen,
  FaMicrophoneLines,
  FaPuzzlePiece,
} from 'react-icons/fa6';

const Cards = () => {
  return (
    <section id='cards' className='bg-white'>
      <div className='container tc'>
        <div className='s-40'></div>
        <h3 className='title-sub'>
          Граємо за правилами, але знаємо всі лазівки
        </h3>
        <p className='title-mini c-dark'>
          акриваємо слабкі місця та допомагаємо отримати
          <br />
          оффер на відкритому ринку або у наших партнерів.
          <br />
          <span className='title-text c-theme fw-700'>
            Ось із чим ми можемо допомогти тобі:
          </span>
        </p>
        <div className='s-40'></div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4 my-3'>
            <div className='card bg-001'>
              <span className='bg-marine jcc aic p-3 r-8'>
                <FaMicrophoneLines size={24} color={'white'} />
              </span>
              <h3 className='title-sub marine'>Запрошення на інтерв'ю</h3>
            </div>
          </div>
          <div className='col-lg-4 my-3'>
            <div className='card bg-002'>
              <span className='bg-magenta jcc aic p-3 r-8'>
                <FaPuzzlePiece size={24} color={'white'} />
              </span>
              <h3 className='title-sub magenta'>Проекти для портфоліо</h3>
            </div>
          </div>
          <div className='col-lg-4 my-3'>
            <div className='card bg-003'>
              <span className='bg-pink jcc aic p-3 r-8'>
                <FaUsers size={24} color={'white'} />
              </span>
              <h3 className='title-sub pink'>
                Круте
                <br />
                ком'юніті
              </h3>
            </div>
          </div>
          <div className='col-lg-8 my-3'>
            <div className='card bg-004'>
              <span className='bg-theme jcc aic p-3 r-8'>
                <FaFileContract size={24} color={'white'} />
              </span>
              <h3 className='title-sub theme'>
                Бажаний оффер
                <br />
                на роботу чи стажування
              </h3>
            </div>
          </div>
          <div className='col-lg-4 my-3'>
            <div className='card bg-005'>
              <span className='bg-blue jcc aic p-3 r-8'>
                <FaChessQueen size={24} color={'white'} />
              </span>
              <h3 className='title-sub blue'>Підтягнемо навички</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { Cards };
