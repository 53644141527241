const plans = [
  {
    header: 'Стажування',
    old: 11499,
    price: 9199,
    features: [
      { text: "3 місяці кар'єрного трекінгу", show: true },
      { text: 'Допомога та трекінг від Senior розробника', show: true },
      { text: 'Організація співбесід від партнерів', show: false },
      { text: 'Пошук та відгуки на 20 вакансій на тиждень', show: false },
    ],
    color: 'blue',
    buttonLabel: 'Інвестувати у сeбe',
  },
  {
    header: 'Оффер',
    old: 29699,
    price: 20499,
    features: [
      { text: "3 місяці кар'єрного трекінгу", show: true },
      { text: 'Допомога та трекінг від Senior розробника', show: true },
      { text: 'Організація співбесід від партнерів', show: true },
      { text: 'Пошук та відгуки на 20 вакансій на тиждень', show: false },
    ],
    color: 'pink',
    buttonLabel: 'Інвестувати у сeбe',
  },
  {
    header: 'Оффер PRO',
    old: 63999,
    price: 45699,
    features: [
      { text: "3 місяці кар'єрного трекінгу", show: true },
      { text: 'Допомога та трекінг від Senior розробника', show: true },
      { text: 'Організація співбесід від партнерів', show: true },
      { text: 'Пошук та відгуки на 20 вакансій на тиждень', show: true },
    ],
    color: 'magenta',
    buttonLabel: 'Інвестувати у сeбe',
  },
];

export default plans;
