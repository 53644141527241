import t001 from '../assets/t001.png';
import t002 from '../assets/t002.png';
import t003 from '../assets/t003.png';
import t004 from '../assets/t004.png';
import t005 from '../assets/t005.png';
import t006 from '../assets/t006.png';
import t007 from '../assets/t007.png';
import t008 from '../assets/t008.png';

const teams = [
  {
    id: 1,
    pic: t001,
    name: 'Андрiй Роговский',
    position: 'CEO',
    company: 'Codereview, IT-Sprout',
  },
  {
    id: 2,
    pic: t002,
    name: 'Антонiна Осипенко',
    position: 'CXO',
    company: 'Codereview, Heyboss',
  },
  {
    id: 3,
    pic: t003,
    name: 'Анна Бондаренко',
    position: 'HR Manager',
    company: 'Codereview',
  },
  {
    id: 4,
    pic: t004,
    name: 'Володимир Петренко',
    position: 'Marketing Director',
    company: 'CraftFlow',
  },
  {
    id: 5,
    pic: t005,
    name: 'Дмитро Савченко',
    position: 'Product Manager',
    company: 'Codereview',
  },
  {
    id: 6,
    pic: t006,
    name: 'Марія Ткаченко',
    position: 'Lead Designer',
    company: 'CraftFlow',
  },
  {
    id: 7,
    pic: t007,
    name: 'Сергій Кузьменко',
    position: 'QA Engineer',
    company: 'Codereview',
  },
  {
    id: 8,
    pic: t008,
    name: 'Наталія Гончарук',
    position: 'Sales Manager',
    company: 'CraftFlow',
  },
];
export default teams;
