import React from 'react';

import { Intro } from './parts/Intro';
import { Students } from './parts/Students';
import { Code } from './parts/Code';
import { Cards } from './parts/Cards';
import { Partners } from './parts/Partners';
import { Plan } from './parts/Plan';
import { Works } from './parts/Works';
import { Team } from './parts/Team';
import { Progress } from './parts/Progress';
import { Pricing } from './parts/Pricing';
import { Stories } from './parts/Stories';
import { Form } from './parts/Form';
import { FAQ } from './parts/FAQ';

const Home = () => {
  return (
    <>
      <Intro />
      <Students />
      <Code />
      <Cards />
      <Partners />
      <Plan />
      <Works />
      <Team />
      <Progress />
      <Pricing />
      <Stories />
      <Form />
      <FAQ />
    </>
  );
};

export { Home };
