import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
  BsBarChart,
  BsPen,
  BsBriefcase,
  BsCommand,
  BsBoxes,
  BsKey,
} from 'react-icons/bs';

const Plan = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 6000,
  };

  return (
    <section id='plan' className='bg-white'>
      <div className='container tc'>
        <div className='s-40'></div>
        <h3 className='title-sub dark'>Як допоможемо тобі отримати оффер</h3>
        <p className='title-mini c-dark w-60 ma'>
          Кар'єрний трекінг{' '}
          <span className='title-text c-theme fw-700'>CodeReview</span> - до
          півроку супроводу на твоєму шляху до офферу разом з експертами в HR та
          програмуванні
        </p>
        <div className='s-40'></div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4'>
            <Slider {...settings}>
              <div className='card c-001 h-80'>
                <h3 className='title-sub white'>Програма</h3>
                <p className='c-white'>Стратегія, Резюме, Супровідний лист</p>
                <p className='title-text c-white'>Упаковка</p>
                <div className='icon'>
                  <BsBarChart size={48} color={'white'} />
                </div>
                <div className='btm'>
                  <p className='title-text c-white'>1-2 тижні</p>
                  <p className='c-white'>LinkedIn, Github, Mock Interview</p>
                </div>
              </div>
              <div className='card c-002 h-80'>
                <h3 className='title-sub white'>Програма</h3>
                <p className='c-white'>
                  Оформлюємо та
                  <br />
                  наповнюємо портфоліо
                </p>
                <p className='title-text c-white'>Перші відгуки</p>
                <div className='icon'>
                  <BsPen size={48} color={'white'} />
                </div>
                <div className='btm'>
                  <p className='title-text c-white'>3-6 тижнів</p>
                  <p className='c-white'>
                    Прокачюємо LinkedIn,
                    <br />
                    Вчимося відрізнятися
                  </p>
                </div>
              </div>
              <div className='card c-003 h-80'>
                <h3 className='title-sub white'>Програма</h3>
                <p className='c-white'>
                  За статистикою перший
                  <br />
                  оффер приходить на 7-9 тижні
                </p>
                <p className='title-text c-white'>Перший оффер</p>
                <div className='icon'>
                  <BsBriefcase size={48} color={'white'} />
                </div>
                <div className='btm'>
                  <p className='title-text c-white'>7-9 тижнів</p>
                  <p className='c-white'>
                    Якщо нема оффера,
                    <br />
                    починаємо План Б
                  </p>
                </div>
              </div>
              <div className='card c-004 h-80'>
                <h3 className='title-sub white'>Програма</h3>
                <p className='c-white'>
                  Стажування від партнерів або
                  <br />
                  стажування в IT-Sprout
                </p>
                <p className='title-text c-white'>
                  План Б. Вихід на
                  <br />
                  стажування
                </p>
                <div className='icon'>
                  <BsCommand size={48} color={'white'} />
                </div>
                <div className='btm'>
                  <p className='title-text c-white'>10-12 тижнів</p>
                  <p className='c-white'>
                    Готуємо до інтерв'ю. 3-6 місяців
                    <br />
                    стажування на реальному проекті
                  </p>
                </div>
              </div>
              <div className='card c-005 h-80'>
                <h3 className='title-sub white'>Програма</h3>
                <p className='c-white'>
                  Додаємо нові проекти в<br />
                  портфоліо, оновлюємо резюме
                </p>
                <p className='title-text c-white'>Відновлюємо пошук</p>
                <div className='icon'>
                  <BsBoxes size={48} color={'white'} />
                </div>
                <div className='btm'>
                  <p className='title-text c-white'>13-16 тижнів</p>
                  <p className='c-white'>
                    Відновлюємо пошук. Аналізуємо
                    <br />
                    конверсії та інтерв'ю
                  </p>
                </div>
              </div>
              <div className='card c-006 h-80'>
                <h3 className='title-sub white'>Програма</h3>
                <p className='c-white'>
                  За статистикою, перший оффер після
                  <br />
                  проходження стажування приходить
                  <br />
                  на 17-24 тижні.
                </p>
                <p className='title-text c-white'>Перший оффер. V2.0</p>
                <div className='icon'>
                  <BsKey size={48} color={'white'} />
                </div>
                <div className='btm'>
                  <p className='title-text c-white'>17-24 тижнів</p>
                  <p className='c-white'>
                    Допомагаємо з<br />
                    переговорами щодо
                    <br />
                    працевлаштування
                  </p>
                </div>
              </div>
            </Slider>
          </div>
          <div className='col-lg-8 h-80 test'>
            <div>
              <h2 className='title-part dark'>Тестування</h2>
              <div className='s-10'></div>
              <p className='c-medium'>
                Перед тим, як записатися на кар'єрний трек, ми оцінимо
              </p>
              <p className='c-dark'>
                <strong>
                  - Твій бекґраунд. Освіта, вік, проекти на Github тощо.
                  <br />
                  - Твої технічні знання. Проведемо з тобою пробну співбесіду.
                  <br />- Резюме, LinkedIn та Github
                </strong>
              </p>
              <p className='c-medium'>
                Щоб зрозуміти твої шанси на працевлаштування. У разі успішного
                проходження тестування – візьмемо тебе на трек
              </p>
              <div className='s-20'></div>
              <a href='/' className='button blue'>
                Пройти тест
              </a>
            </div>
          </div>
          <div className='s-80'></div>
        </div>
      </div>
    </section>
  );
};

export { Plan };
