import React from 'react';
import { MDBAccordion, MDBAccordionItem, MDBContainer } from 'mdb-react-ui-kit';

const FAQ = () => {
  return (
    <section id='faq' className='bg-white'>
      <div className='s-20'></div>
      <div className='container tc'>
        <div className='s-40'></div>
        <h3 className='title-sub dark w-80 ma'>F.A.Q</h3>
      </div>
      <div className='container'>
        <div className='w-80 m-100 ma'>
          <MDBContainer>
            <MDBAccordion alwaysOpen initialActive={1}>
              <MDBAccordionItem
                collapseId={1}
                headerTitle='1. Які гарантії ви даєте?'
                className='title-mini fw-400'
              >
                На жаль, ми не можемо гарантувати тобі 100% працевлаштування,
                оскільки в будь-якому випадку це спільна робота, яка вимагає
                зусиль також і від тебе. Попередній досвід нашої роботи свідчить
                про те, що 92% наших студентів знаходили роботу протягом 6
                місяців після початку кар'єрного трекінгу.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={2}
                headerTitle='2. Чи можна сплатити з першої зарплати?'
                className='title-mini fw-400'
              >
                Так, оплатити % з оффера можна протягом 45 днів після отримання
                оффера. Оплатити можна з першої зарплати, а також оплатити в
                розстрочку, без відсотків.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={3}
                headerTitle='3. Чи є у вас розстрочка?'
                className='title-mini fw-400'
              >
                Так, ми надаємо безпроцентну розстрочку від ПриватБанку на
                період від 3 до 6 місяців.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={4}
                headerTitle='4. Чи вся підготовка проходить онлайн?'
                className='title-mini fw-400'
              >
                Так, всі консультації проходять онлайн через Zoom. Ви самі
                обираєте зручний для вас час.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={5}
                headerTitle='5. З якими країнами ви працюєте?'
                className='title-mini fw-400'
              >
                На даний момент ми приймаємо студентів з України, Казахстану та
                Узбекистану.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={6}
                headerTitle='6. У який час проходять консультації та заняття?'
                className='title-mini fw-400'
              >
                Розклад консультацій погоджується заздалегідь, зустрічі
                проходять у зручний для вас час.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={7}
                headerTitle=' 7. Чи можу я відмовитися від оффера, якщо він не підходить?'
                className='title-mini fw-400'
              >
                Це залежить від обраного тарифу. У більшості тарифів є
                можливість відмовитися від одного пропозиції про роботу, після
                відмови від другого доведеться заплатити бонус за
                працевлаштування.
              </MDBAccordionItem>
            </MDBAccordion>
          </MDBContainer>
        </div>
      </div>
      <div className='s-100'></div>
    </section>
  );
};
export { FAQ };
