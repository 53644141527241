import s001 from '../assets/s001.png';
import s002 from '../assets/s002.png';
import s003 from '../assets/s003.png';
import s004 from '../assets/s004.png';
import s005 from '../assets/s005.png';
import s006 from '../assets/s006.png';
import s007 from '../assets/s007.png';
import s008 from '../assets/s008.png';
import s009 from '../assets/s009.png';
import s010 from '../assets/s010.png';
import s011 from '../assets/s011.png';
import s012 from '../assets/s012.png';
import s013 from '../assets/s013.png';
import s014 from '../assets/s014.png';
import s015 from '../assets/s015.png';
import s016 from '../assets/s016.png';
import s017 from '../assets/s017.png';
import s018 from '../assets/s018.png';
import s019 from '../assets/s019.png';
import s020 from '../assets/s020.png';

const stories = [
  {
    pic: s001,
    name: 'Андрій Іваненко',
    link: 'https://linkedin.com',
    text: "Я швидко знайшов роботу програміста завдяки участі в реальних проектах під час навчання. Ментори допомогли мені заповнити портфоліо та підготуватися до інтерв'ю з потенційним роботодавцем. Я отримав цінний досвід командної роботи.",
  },
  {
    pic: s002,
    name: 'Олена Петрівна',
    link: 'https://linkedin.com',
    text: 'Я знайшла свою мрійну роботу дизайнера завдяки підтримці менторів, які допомогли мені створити резюме та підготуватися до співбесід. Я отримала чудовий досвід у реальних проектах.',
  },
  {
    pic: s003,
    name: 'Сергій Коваленко',
    link: 'https://linkedin.com',
    text: "Я завжди мріяв працювати в IT. Під час навчання я брав участь у командних проектах, що допомогло мені швидко знайти роботу розробника. Ментори сприяли в підготовці до інтерв'ю та створенні резюме. Я здобув важливі практичні навички.",
  },
  {
    pic: s004,
    name: 'Ірина Смирнова',
    link: 'https://linkedin.com',
    text: 'Я швидко знайшла вакансію тестувальника завдяки активній участі в реальних проектах під час навчання. Ментори надали допомогу в створенні портфоліо та підготовці до співбесід. Я отримала багатий досвід роботи в команді.',
  },
  {
    pic: s005,
    name: 'Микола Григоренко',
    link: 'https://linkedin.com',
    text: 'Я завдяки підтримці менторів та участі в реальних проектах під час навчання швидко знайшов роботу frontend-розробника. Я отримав корисні навички для успішного проходження співбесіди та цінний досвід у командній роботі.',
  },
  {
    pic: s006,
    name: 'Лариса Коваленко',
    link: 'https://linkedin.com',
    text: 'Я брала активну участь у командних проектах під час навчання на веб-дизайнера. Ментори допомогли мені створити потужне портфоліо та підготуватися до співбесід, завдяки чому я швидко знайшла роботу. Я здобула важливий досвід у реальних проектах.',
  },
  {
    pic: s007,
    name: 'Павло Тарасенко',
    link: 'https://linkedin.com',
    text: 'Я знайшов роботу своєї мрії як системний адміністратор завдяки участі в реальних проектах під час навчання та допомозі менторів. Я отримав необхідні навички та підготовку до співбесід, а також цінний досвід командної роботи.',
  },
  {
    pic: s008,
    name: 'Катерина Мельник',
    link: 'https://linkedin.com',
    text: 'Я активно брала участь у командних проектах під час навчання на графічного дизайнера. Ментори допомогли мені з резюме та підготовкою до співбесід, що сприяло швидкому працевлаштуванню. Я отримала важливий досвід у реальних проектах.',
  },
  {
    pic: s009,
    name: 'Олег Бондаренко',
    link: 'https://linkedin.com',
    text: "Я швидко знайшов роботу backend-розробника завдяки участі в реальних проектах під час навчання. Ментори надали допомогу в створенні портфоліо та підготовці до інтерв'ю. Я здобув цінний досвід у командній роботі.",
  },
  {
    pic: s010,
    name: 'Анна Шевченко',
    link: 'https://linkedin.com',
    text: "Я завдяки активній участі в реальних проектах та підтримці менторів під час навчання швидко знайшла роботу UI/UX дизайнера. Я отримала необхідні навички для успішного проходження інтерв'ю та багатий досвід роботи в команді.",
  },
  {
    pic: s011,
    name: 'Віктор Лисенко',
    link: 'https://linkedin.com',
    text: 'Я знайшов вакансію своєї мрії як веб-розробник завдяки підтримці менторів, які допомогли мені з підготовкою до співбесід. Я отримав цінний досвід участі в реальних проектах.',
  },
  {
    pic: s012,
    name: 'Марія Романюк',
    link: 'https://linkedin.com',
    text: 'Я активно брала участь у командних проектах під час навчання на аналітика даних. Ментори допомогли мені з резюме та підготовкою до співбесід, що сприяло швидкому працевлаштуванню. Я отримала важливий досвід у реальних проектах.',
  },
  {
    pic: s013,
    name: 'Дмитро Захарченко',
    link: 'https://linkedin.com',
    text: 'Я завдяки участі в реальних проектах під час навчання та допомозі менторів знайшов роботу своєї мрії як DevOps інженер. Я отримав необхідні навички та підготовку до співбесід, а також цінний досвід командної роботи.',
  },
  {
    pic: s014,
    name: 'Юлія Остапенко',
    link: 'https://linkedin.com',
    text: 'Я швидко знайшла вакансію веб-дизайнера завдяки командній роботі під час навчання. Ментори допомогли мені з портфоліо та підготовкою до співбесід. Я тепер успішний спеціаліст у своїй галузі та маю багатий досвід роботи в реальних проектах.',
  },
  {
    pic: s015,
    name: 'Василь Кравченко',
    link: 'https://linkedin.com',
    text: 'Я знайшов роботу програміста завдяки участі в реальних проектах та підтримці менторів під час навчання. Я отримав корисні навички для успішного проходження співбесіди та цінний досвід у командній роботі.',
  },
  {
    pic: s016,
    name: 'Світлана Гончаренко',
    link: 'https://linkedin.com',
    text: "Я завдяки участі в командних проектах під час навчання швидко знайшла роботу тестувальника. Ментори допомогли мені заповнити портфоліо та підготуватися до інтерв'ю з потенційним роботодавцем. Я отримала цінний досвід роботи в команді.",
  },
  {
    pic: s017,
    name: 'Олександр Вітер',
    link: 'https://linkedin.com',
    text: 'Я швидко знайшов роботу QA інженера завдяки активній участі в реальних проектах під час навчання. Ментори допомогли мені з підготовкою до співбесід та створенням резюме. Я здобув важливий досвід у командній роботі.',
  },
  {
    pic: s018,
    name: 'Наталія Зоря',
    link: 'https://linkedin.com',
    text: 'Я швидко знайшла вакансію аналітика даних завдяки підтримці менторів під час навчання. Я отримала необхідні навички та підготовку до співбесід, а також цінний досвід участі в реальних проектах.',
  },
  {
    pic: s019,
    name: 'Денис Левченко',
    link: 'https://linkedin.com',
    text: 'Я завдяки участі в реальних проектах під час навчання знайшов роботу своєї мрії як веб-розробник. Ментори допомогли мені з підготовкою до співбесід та створенням портфоліо. Я отримав цінний досвід у командній роботі.',
  },
  {
    pic: s020,
    name: 'Людмила Соколовська',
    link: 'https://linkedin.com',
    text: 'Я активно брала участь у командних проектах під час навчання на UI/UX дизайнера. Ментори допомогли мені з резюме та підготовкою до співбесід, що сприяло швидкому працевлаштуванню. Я здобула важливий досвід у реальних проектах.',
  },
];

export default stories;
