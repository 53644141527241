import React from 'react';
import plans from '../../data/plans';

const Pricing = () => {
  return (
    <section id='pricing' className='bg-white'>
      <div className='container tc'>
        <div className='s-40'></div>
        <h3 className='title-sub'>Тарифні плани</h3>
        <p className='title-mini c-dark'>Вибери собі підходящий тариф</p>
        <div className='s-60'></div>
      </div>
      <div className='container'>
        <div className='row'>
          {plans.map((item) => (
            <div className='col-lg-4' key={item.index}>
              <div className='card mb-4 shadow-sm tc'>
                <div className={`card-header bg-${item.color}`}>
                  <h4 className='c-white m-0 p-3'>
                    <span className='fw-400 upp'>{item.header}</span>
                  </h4>
                </div>
                <div className='card-body'>
                  <h1 className='card-title pricing-card-title p-4 bg-light'>
                    <span className='title-sub'>{item.price}</span>{' '}
                    <small>UAH / за мiсяць</small>
                  </h1>
                  <div className='w-80 ma tl'>
                    <ul className='list-unstyled'>
                      {item.features.map((feature, i) => (
                        <li
                          key={i}
                          className={
                            feature.show
                              ? 'c-dark title-text'
                              : 'c-lite title-text'
                          }
                        >
                          {feature.show ? feature.text : <s>{feature.text}</s>}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className='s-20'></div>
                  <a href='/' className={`button bg-${item.color}`}>
                    {item.buttonLabel}
                  </a>
                  <div className='s-10'></div>
                </div>
              </div>
              <div className='s-100'></div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export { Pricing };
