import i001 from '../assets/i001.png';
import i002 from '../assets/i002.png';
import i003 from '../assets/i003.png';
import i004 from '../assets/i004.png';
import i005 from '../assets/i005.png';
import i006 from '../assets/i006.png';
import i007 from '../assets/i007.png';
import i008 from '../assets/i008.png';
import i009 from '../assets/i009.png';
import i010 from '../assets/i010.png';
import i011 from '../assets/i011.png';
import i012 from '../assets/i012.png';

const jobs = [
  {
    id: 1,
    title: 'Dev Ops',
    text: 'Docker, Linux, Kubernates',
    icon: i001,
    back: '#DCFFFD',
  },
  {
    id: 2,
    title: 'Frontend Dev',
    text: 'React, React Native',
    icon: i002,
    back: '#FFE8DE',
  },
  {
    id: 3,
    title: 'Python Web',
    text: 'Flask, Django, TG боти',
    icon: i003,
    back: '#CFCEFF',
  },
  {
    id: 4,
    title: 'UI/UX Design',
    text: 'Adobe, Figma',
    icon: i004,
    back: '#F6FFBC',
  },
  {
    id: 5,
    title: 'Data Science',
    text: 'ML, Продуктова аналiтика',
    icon: i005,
    back: '#FFD0FA',
  },
  {
    id: 6,
    title: 'Java Backend',
    text: 'Spring, Kibernate',
    icon: i006,
    back: '#C0E8FF',
  },
  {
    id: 7,
    title: 'Swift',
    text: 'Мобiльна розробка',
    icon: i007,
    back: '#FFECD1',
  },
  {
    id: 8,
    title: 'Kotlin',
    text: 'Мобiльна розробка',
    icon: i008,
    back: '#ECD3FF',
  },
  {
    id: 9,
    title: 'C++',
    text: 'Сi подiбнi мови',
    icon: i009,
    back: '#DDFFF9',
  },
  {
    id: 10,
    title: 'GO',
    text: 'Beego, Kit, Echo',
    icon: i010,
    back: '#FFDFF2',
  },
  {
    id: 11,
    title: 'Game Dev',
    text: 'Розробка iгор',
    icon: i011,
    back: '#D3DFFF',
  },
  {
    id: 12,
    title: 'QA',
    text: 'Мануальне та автоматизоване тестування',
    icon: i012,
    back: '#FED3E0',
  },
];

export default jobs;
