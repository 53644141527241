import React from 'react';
import Masonry from 'react-masonry-css';
import 'bootstrap/dist/css/bootstrap.min.css';
import stories from '../../data/stories';

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1,
};

const Stories = () => {
  return (
    <section id='stories' className='bg-white'>
      <div className='container'>
        <div className='s-40'></div>
        <div className='tc'>
          <h3 className='title-sub dark'>Історії успіху</h3>
        </div>

        <div className='s-40'></div>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className='my-masonry-grid'
          columnClassName='my-masonry-grid_column'
        >
          {stories.map((story, index) => (
            <div className='card' key={index}>
              <div className='p-2'>
                <div className='flex jcsb aic bg-white px-3 border-bottom'>
                  <img
                    src={story.pic}
                    className='obfc'
                    alt={story.name}
                    width={40}
                    height={40}
                    style={{ marginBottom: 4 }}
                  />
                  <div className='w-100 p-3'>
                    <h5 className='title-text c-dark m-0'>{story.name}</h5>
                    <a href={story.link} className='title-mini c-dark'>
                      LinkedIn
                    </a>
                  </div>
                </div>
                <div className='card-body m-0'>
                  <p className='card-text'>{story.text}</p>
                </div>
              </div>
            </div>
          ))}
        </Masonry>
      </div>
      <div className='s-100'></div>
    </section>
  );
};

export { Stories };
