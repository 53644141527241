import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import jobs from '../../data/jobs';

const Works = () => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 6000,
  };

  return (
    <section id='works' className='bg-tr'>
      <div className='container wrk'>
        <div className='tc'>
          <div className='s-40'></div>
          <h3 className='title-sub dark'>Професії</h3>
          <p className='title-mini c-dark'>
            На які спеціальності можемо влаштувати
          </p>
          <div className='s-40'></div>
        </div>
        <div className='container'>
          <Slider {...settings}>
            {jobs.map((e) => (
              <div key={e.id} className='px-2'>
                <div
                  className='px-4 py-3 h-30 w-100 r-16'
                  style={{ backgroundColor: e.back }}
                >
                  <div className='flex jcsb c-dark h-20'>
                    <h3 className='title'>{e.title}</h3>
                    <img
                      src={e.icon}
                      alt={e.title}
                      width={80}
                      height={80}
                      style={{ objectFit: 'scale-down' }}
                    />
                  </div>
                  <p className='c-dark'>{e.text}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <div className='s-80'></div>
      <div className='container tc'>
        <div className='s-40'></div>
        <h3 className='title-sub white'>
          Знаємо 50+ джерел вакансій, про які всі мовчать!
        </h3>
        <p className='title-mini c-white'>
          Віддамо безкоштовно, просто залиш свій email — надішлемо на пошту
          найближчим часом
        </p>
        <div className='s-40'></div>
        <a href='/' className='button'>
          Отримати список
        </a>
      </div>
      <div className='s-80'></div>
    </section>
  );
};

export { Works };
