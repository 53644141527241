import React, { useState } from 'react';

const Form = () => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
    telegram: '',
    query: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const validate = () => {
    let formErrors = {};
    if (!form.name) formErrors.name = "Будь ласка, введіть своє ім'я.";
    if (!form.email)
      formErrors.email = 'Будь ласка, введіть свою електронну адресу.';
    if (!form.phone)
      formErrors.phone = 'Будь ласка, введіть свій номер телефону.';
    if (!form.telegram)
      formErrors.telegram = 'Будь ласка, введіть свій нік у Телеграм.';
    if (!form.query)
      formErrors.query = 'Будь ласка, опишіть свій запит та мету.';
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // Обработка формы
      alert('Форма успішно відправлена!');
    }
  };

  return (
    <section id='form' className='bg-white'>
      <div className='container tc'>
        <div className='s-40'></div>
        <h3 className='title-sub w-80 ma'>
          Дій зараз, щоб найближчим часом прийняти оффер
        </h3>
        <p className='title-mini c-dark w-60 ma'>
          Запишись на наше тестування і у разі успішного проходження – візьмемо
          тебе на трек
        </p>
        <div className='s-20'></div>
      </div>
      <div className='container'>
        <div className='w-60 ma'>
          <form onSubmit={handleSubmit} noValidate>
            <div className='row'>
              <div className='form-group col-lg-6 my-2'>
                <input
                  type='text'
                  className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                  id='name'
                  name='name'
                  placeholder='Як тебе звати'
                  value={form.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <div className='invalid-feedback'>{errors.name}</div>
                )}
              </div>

              <div className='form-group col-lg-6 my-2'>
                <input
                  type='email'
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  id='email'
                  name='email'
                  placeholder='Електронна адреса'
                  value={form.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <div className='invalid-feedback'>{errors.email}</div>
                )}
              </div>
            </div>

            <div className='row'>
              <div className='form-group col-lg-6 my-2'>
                <input
                  type='tel'
                  className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                  id='phone'
                  name='phone'
                  placeholder='Номер телефону'
                  value={form.phone}
                  onChange={handleChange}
                />
                {errors.phone && (
                  <div className='invalid-feedback'>{errors.phone}</div>
                )}
              </div>

              <div className='form-group col-lg-6 my-2'>
                <input
                  type='text'
                  className={`form-control ${
                    errors.telegram ? 'is-invalid' : ''
                  }`}
                  id='telegram'
                  name='telegram'
                  placeholder='@ Ник у телеграм'
                  value={form.telegram}
                  onChange={handleChange}
                />
                {errors.telegram && (
                  <div className='invalid-feedback'>{errors.telegram}</div>
                )}
              </div>
            </div>

            <div className='form-group my-2'>
              <textarea
                className={`form-control ${errors.query ? 'is-invalid' : ''}`}
                id='query'
                name='query'
                placeholder='Опиши свiй запит та мету, що ти хочеш отримати вiд пробноi консультацii'
                rows='5'
                value={form.query}
                onChange={handleChange}
              />
              {errors.query && (
                <div className='invalid-feedback'>{errors.query}</div>
              )}
            </div>
            <div className='my-3'>
              <button type='submit' className='button'>
                Записатися на тестування
              </button>
            </div>
            <div className='s-10'></div>

            <p className='title-mini c-dark tc'>
              А ще у нас є Telegram-канал, де регулярно виходять круті пости про
              працевлаштування в IT —{' '}
              <a href='/' className='c-theme'>
                підпишись
              </a>
              , щоб стежити за ринком і бути в одному інфополі з конкурентами на
              круті вакансії та стажування
            </p>
          </form>
        </div>
      </div>
      <div className='s-100'></div>
    </section>
  );
};

export { Form };
