import React from 'react';
import { NavPages } from '../components/NavPages';

const Error = () => {
  return (
    <>
      <NavPages />
      <section>
        <div className='container tc'>
          <div className='s-80'></div>
          <div className='w-100 flex aic jcc'>
            <div className='h-40'>
              <h3 className='title-sub dark w-100'>Сторінку не знайдено</h3>
              <p className='title-text c-dark'>
                Перевірте правильність введеної адреси
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { Error };
