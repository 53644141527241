import React from 'react';
import tech from '../../assets/stacks.png';

const Partners = () => {
  return (
    <section id='about' className='bg-white'>
      <div className='container'>
        <div className='s-80'></div>
        <div className='row'>
          <div className='col-lg-5 flex aic'>
            <div>
              <h4 className='title-part'>З ким ми працюємо</h4>
              <p className='c-dark fw-500 w-90'>
                Ми співпрацюємо з різними компаніями, щоб допомогти тобі
                якнайшвидше пробитися на інтерв'ю. Крім цього, у нас є велика
                база контактів з більш ніж 12.000 рекрутерів з різних IT
                компаній. Ми будемо раді рекомендувати твоє резюме нашим колегам
                з цеху
              </p>
            </div>
          </div>
          <div className='col-lg-7 flex aic'>
            <img src={tech} alt='Partners' width={638} height={422} />
          </div>
        </div>
        <div className='s-80'></div>
      </div>
    </section>
  );
};

export { Partners };
