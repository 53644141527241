import React from 'react';
import Marquee from 'react-fast-marquee';
import { Nav } from '../../components/Nav';
import clients from '../../data/clients';

const Intro = () => {
  return (
    <section id='top'>
      <div className='intro-content'>
        <Nav />
        <div className='container tc h-100 flex jcc aic'>
          <div className='w-80 ma'>
            <div className='s-40'></div>
            <h1 className='title-intro'>
              Твій помічник з працевлаштування в IT
            </h1>
            <div className='s-20'></div>
            <div className='w-70 ma tc'>
              <h3 className='title-mini c-white fw-400'>
                Знайдемо вакансію або стажування в топової IT - компанії та
                супроводжуватимемо до отримання офферу. Або повернемо гроші
              </h3>
              <div className='s-40'></div>
              <a href='/' className='button w-60'>
                Записатися на тестування
              </a>
            </div>
            <div className='s-100'></div>
          </div>
        </div>
      </div>
      <div className='intro-bottom bg-white'>
        <div className='s-60'></div>
        <div className='row'>
          <Marquee>
            {clients.map((item) => (
              <div className='col-lg-2' id={item.id}>
                <div className='flex jcc aic p-5 mx-2 h-30'>
                  <div>
                    <img
                      src={item.pic}
                      alt={item.name}
                      width={100}
                      height={100}
                      className='obfc greyscale'
                    />
                  </div>
                </div>
              </div>
            ))}
          </Marquee>
        </div>
      </div>
      <div class='background'>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </section>
  );
};

export { Intro };
