import React, { useState } from 'react';
import p1 from '../../assets/p001.png';
import p2 from '../../assets/p002.png';

const Code = () => {
  const [activeContent, setActiveContent] = useState('A');

  const ContentA = () => {
    return (
      <div className='row'>
        <div className='col-lg-6'>
          <img
            src={p1}
            alt='Trainee & Junior'
            width={500}
            height={500}
            className='obfc'
          />
        </div>
        <div className='col-lg-6 px-5'>
          <h3 className='title-part'>Trainee & Junior</h3>
          <div className='s-10'></div>
          <h4 className='title-text c-theme'>Немає запрошень на інтерв'ю</h4>
          <p>
            Ти зробив уже 100-200 відгуків, але рекрутери надсилають одні
            відмови чи зовсім не відповідають. Твій відгук губиться серед сотні
            інших кандидатів.
          </p>
          <h4 className='title-text c-theme'>
            Відмова після тестового чи інтерв'ю
          </h4>
          <p>
            Тобі надсилають однотипні відмови після вирішення тестового завдання
            чи проходження інтерв'ю. Ти не розумієш, що ти зробив не так, і над
            чим потрібно працювати
          </p>
          <h4 className='title-text c-theme'>
            Чому складно працевлаштуватися?
          </h4>
          <p>
            - HR звертають увагу лише на тих, хто має комерційний досвід — але
            ніхто не пише, де його взяти
          </p>
          <p>
            - Близько 890 відгуків надсилається одну вакансію Junior-розробника
          </p>
          <p>
            - Великий ризик потрапити до команди з низькою технічною культурою,
            де буде складно зростати та розвиватися
          </p>
        </div>
      </div>
    );
  };

  const ContentB = () => {
    return (
      <div className='row'>
        <div className='col-lg-6'>
          <img
            src={p2}
            alt='Trainee & Junior'
            width={500}
            height={500}
            className='obfc'
          />
        </div>
        <div className='col-lg-6 p-5'>
          <h3 className='title-part'>Middle & Senior</h3>
          <div className='s-10'></div>
          <h4 className='title-text c-theme'>Ти перестав рости</h4>
          <p className='w-70'>
            У тій компанії де ти працюєш, тобі нема на кого рівнятися, тобі не
            дають зворотного зв'язку за кодом і ти розумієш, що ти застряг на
            місці.
          </p>
          <h4 className='title-text c-theme'>Ти стоїш більше, ніж отримуєш</h4>
          <p className='w-70'>
            Ти працював в одній компанії 2-3 роки, але твоя зарплата не
            змінилася. Ти розумієш, що зараз ти стоїш більше, ніж 2 роки тому, і
            хочеш знайти ту компанію, яка оцінить твої навички
          </p>
        </div>
      </div>
    );
  };

  return (
    <section id='about' className='bg-white'>
      <div className='container tc'>
        <div className='s-40'></div>
        <h3 className='title-sub dark'>Codereview</h3>
        <p className='title-mini c-dark'>
          Ми codereview, команда, яка працевлаштувала
          <br />
          <span className='title-text c-theme fw-700'>
            понад 1500 розробників за три роки
          </span>
        </p>
        <div className='s-40'></div>
      </div>
      <div className='tc'>
        <span className='tabs'>
          <button
            onClick={() => setActiveContent('A')}
            className={
              activeContent === 'A' ? 'button tab active' : 'button tab'
            }
          >
            Trainee & Junior
          </button>
          <button
            onClick={() => setActiveContent('B')}
            className={
              activeContent === 'B' ? 'button tab active' : 'button tab'
            }
          >
            Middle & Senior
          </button>
        </span>
      </div>
      <div className='container-fluid p-0'>
        <div className='s-80'></div>
        {activeContent === 'A' ? <ContentA /> : <ContentB />}
        <div className='s-80'></div>
      </div>
    </section>
  );
};

export { Code };
