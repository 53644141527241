import React from 'react';
import teams from '../../data/teams';

const Team = () => {
  return (
    <section id='team' className='bg-tr-50'>
      <div className='container'>
        <div className='s-100'></div>
        <h3 className='title-sub white m-0'>Команда</h3>
        <div className='s-10'></div>
        <p className='title-text c-white fw-500'>
          Герої, які пройдуть з тобою крізь темряву,
          <br />
          щоб вивести тебе на світ
        </p>
        <div className='s-20'></div>
        <div className='row'>
          {teams.map((item) => (
            <div className='col-lg-3 my-2' id={item.id}>
              <div className='s-10'></div>
              <div className='px-4 py-2 bg-team r-24'>
                <div className='s-10'></div>
                <img src={item.pic} alt={item.name} width={80} height={80} />
                <div className='s-10'></div>
                <h3 className='c-white title-mini'>{item.name}</h3>
                <p className='c-white'>{item.position}</p>
                <div style={{ height: 1, backgroundColor: 'white' }}></div>
                <div className='s-10'></div>
                <p className='c-white'>{item.company}</p>
              </div>
            </div>
          ))}
        </div>
        <div className='s-80'></div>
      </div>
    </section>
  );
};

export { Team };
