import React from 'react';

import logo from '../logo.svg';

const NavPages = () => {
  return (
    <header className='w-100 bg-medium'>
      <div className='container'>
        <header className='flex jcsb aic h-10 py-5'>
          <h1 id='logo'>
            <a href='/' aria-label='Codereview - Home'>
              <img src={logo} width={158} height={48} alt='Codereview' />
            </a>
          </h1>
          <div className='flex jcfe aic mobile'>
            <a href='/' className='mx-4' aria-label='Codereview'>
              Головна
            </a>
          </div>
        </header>
      </div>
    </header>
  );
};

export { NavPages };
