import React from 'react';
import logo from '../logo.svg';

const Footer = () => {
  const year = new Date();
  return (
    <footer id='contact' className='bg-tr'>
      <div className='container'>
        <div className='s-40'></div>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='s-m-40'></div>
            <a href='/' aria-label='Codereview - Home'>
              <img
                src={logo}
                width={158}
                height={48}
                alt='Codereview'
                style={{ transform: 'translateY(-5px)' }}
              />
            </a>
            <div className='s-40'></div>
            <div className='s-m-40'></div>
            <p className='c-white'>
              <a href='/'>@{year.getFullYear()} - Codereview</a>. Усі права
              захищено
            </p>
          </div>
          <div className='col-lg-6 tr'>
            <div className='s-20'></div>
            <address className='c-white'>
              Громадська організація «Codereview»
              <br />
              <div className='s-10'></div>
              {/*ЄДРПОУ: XXXXXXXX*/}
            </address>
            <div className='s-30'></div>
            <a href='/' className='c-white'>
              Політика конфіденційності
            </a>
          </div>
        </div>
        <div className='s-30'></div>
      </div>
    </footer>
  );
};

export { Footer };
