import React from 'react';
import pic from '../../assets/interns.png';

const Students = () => {
  return (
    <section id='interns' className='bg-white'>
      <div className='s-80'></div>
      <div className='container tc flex jcc aic'>
        <div>
          <h2 className='title-sub'>Наші випускники тепер у добрих руках</h2>
          <div className='s-60'></div>
          <img
            src={pic}
            alt='випускники'
            width={'100%'}
            height={'100%'}
            className='w-100'
          />
        </div>
      </div>
      <div className='s-80'></div>
    </section>
  );
};

export { Students };
