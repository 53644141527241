import React from 'react';
import Marquee from 'react-fast-marquee';
import {
  BsCashStack,
  BsPeople,
  BsCalendar3,
  BsCheckCircle,
} from 'react-icons/bs';

const Progress = () => {
  return (
    <section id='progress' className='bg-tr-50'>
      <div className='container'>
        <div className='w-90 ma'>
          <div className='row'>
            <div className='col-lg-6 my-3'>
              <h3 className='title-intro c-pink p-5 flex jcc aic'>
                Що ми вже зробили
              </h3>
            </div>
            <div className='col-lg-6 my-3'>
              <div className='bg-dark-50 r-24 p-5'>
                <div className='flex jcsb aic'>
                  <h3 className='title-sub m-0 white fw-600'>1500+</h3>
                  <BsPeople size={40} color={'white'} />
                </div>
                <div className='s-30'></div>
                <p className='title-text c-white fw-400'>
                  Працевлаштованих розробників за 3 роки
                </p>{' '}
              </div>
            </div>
            <div className='col-lg-6 my-3'>
              <div className='bg-dark-50 r-24 p-5'>
                <div className='flex jcsb aic'>
                  <h3 className='title-sub m-0 white fw-600'>1 мiсяць</h3>
                  <BsCalendar3 size={40} color={'white'} />
                </div>
                <div className='s-30'></div>
                <p className='title-text c-white fw-400'>
                  Середній час, за який ми знаходимо роботу
                </p>
              </div>
            </div>
            <div className='col-lg-6 my-3'>
              <div className='bg-dark-50 r-24 p-5'>
                <div className='flex jcsb aic'>
                  <h3 className='title-sub m-0 white fw-600'>$1500</h3>
                  <BsCashStack size={40} color={'white'} />
                </div>
                <div className='s-30'></div>
                <p className='title-text c-white fw-400'>
                  Середня зарплатня нашого випускника
                </p>
              </div>
            </div>
            <div className='s-20'></div>
            <div className='px-3'>
              <div className='w-100 ma bg-dark-50 r-24 p-4 flex jcsb aic'>
                <div className='w-70 p1'>
                  <Marquee>
                    <p className='title-text c-white fw-400 mx-4'>
                      <span className='icon-check'>
                        <BsCheckCircle color={'#3A91FC'} size={20} />
                      </span>
                      Junior: 8300+ офферiв із середньою зарплатою $700
                      &nbsp;&nbsp;
                      <span className='icon-check'>
                        <BsCheckCircle color={'#3A91FC'} size={20} />
                      </span>
                      Middle: 5700+ офферiв із середньою зарплатою $1200
                      &nbsp;&nbsp;
                      <span className='icon-check'>
                        <BsCheckCircle color={'#3A91FC'} size={20} />
                      </span>
                      Senior: 2100+ офферiв із середньою зарплатою $1500
                    </p>
                  </Marquee>
                </div>
                <div className='w-30 tr'>
                  <a href='/' className='button px-4'>
                    Хочу отримати оффер
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='s-80'></div>
        </div>
      </div>
    </section>
  );
};

export { Progress };
